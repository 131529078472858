<template lang="html">
  <div class="about">
    <div class="box">
      <p>智慧农贸就是利用物联网、云计算、大数据、人工智能等信息技术，通过互联网+实现智能化经营和管理模式，达到信息实时化、具体化、可视化，以及智能支付（智能卡支付、移动支付等）、计量监管、食品溯源 (含追溯电子秤、二维码溯源)、数据分析及信息发布等功能的农贸市场。</p>
      <p>牧予科技颠覆传统的【智慧农贸溯源管理系统】建设，取得了很好的应用效果。通过系统建设，重点实现了“智能称重收银、菜价交易公示、商户信息公示、交易数据采集、智能检测、食品安全及交易追溯、大数据管理、物联网交易、信息查询、互联网+买菜系统等功能”等主要功能，使农贸市场在运营、管理、服务、监管和大数据应用方面，能更快更好的实现信息网络化、透明化、工作规范化、管理现代化。</p>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'about',
    data() {
      return {};
    }
  };
</script>

<style lang="scss" scoped>
  .about {
    width: 100%;
    height: calc(100vh - 340px);
    background: url('../../assets/image/guanyuBk.png') no-repeat;
    background-size: 100% 410px;
    padding-top: 340px;
    .box {
      padding: 50px 40px 0 40px;
      border-radius: 30px 30px 0px 0px;
      background: #FFFFFF;
      font-size: 28px;
      color: #000000;
      p {
        text-indent: 2em;
        margin-bottom: 20px;
        line-height: 42px;
      }
    }
  }
</style>
